<template>
  <div>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules"  label-width="140px" class="demo-ruleForm" >
      <div style="background-color: white">
        <div class="box">
          <el-row class="row">
            <el-col :span="24">
              <el-form-item>
                <template #label><span class="title">绑定日期</span></template>
                <span class="content">{{ruleForm.createTime}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row" v-if="this.role == 1">
            <el-col :span="24">
              <el-form-item>
                <template #label><span class="title">所属机构</span></template>
                <span class="content">{{ruleForm.unitName}}</span>
              </el-form-item>
            </el-col>
          </el-row>
          
          <el-row class="row">
            <el-col :span="24">
                <el-form-item>
                  <template #label><span class="title">设备编号</span></template>
                  <span class="content">{{ruleForm.deviceId}}</span>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
                <el-form-item>
                  <template #label><span class="title">设备名称</span></template>
                  <span class="content">{{ruleForm.deviceName}}</span>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
                <el-form-item>
                  <template #label><span class="title">设备类型</span></template>
                  <span class="content">{{ruleForm.deviceType}}</span>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
                <el-form-item>
                  <template #label><span class="title">设备型号</span></template>
                  <span class="content">{{ruleForm.deviceModel}}</span>
                </el-form-item>
            </el-col>
          </el-row>

          <el-row class="row">
            <el-col :span="24">
                <el-form-item>
                  <template #label><span class="title">信息备注</span></template>
                  <el-input v-show="edit == true" v-model="ruleForm.remarks" placeholder="请输入信息备注" class="input"></el-input>
                  <span v-show="edit == false" class="content">{{ruleForm.remarks}}</span>
                </el-form-item>
            </el-col>
          </el-row>
          <hr />
          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="userName">
                  <template #label><span class="title">绑定用户</span></template>
                  <el-input  v-show="edit == true"  v-model="ruleForm.userName" placeholder="请选择用户"  class="input" disabled>
                    <template #append>
                      <el-button  @click="this.userPage = true" type="primary"  style="color: white" >选择用户</el-button>
                    </template>
                  </el-input>
                  <span v-show="edit == false" class="content">{{ruleForm.userName}}</span>
                  <el-button v-show="edit == false"  @click="clearUser" type="primary" size="small" style="color: white;margin-left:10px" >清除</el-button>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
                <el-form-item prop="noticeName">
                  <template #label><span class="title">管&ensp;理&ensp;人</span></template>
                  <el-input  v-show="edit == true" v-model="ruleForm.noticeName"  placeholder="请选择管理人"  class="input" disabled>
                    <template #append>
                      <el-button @click="this.managerPage = true" type="primary" style="color: white" >选择管理</el-button>
                    </template>
                  </el-input >
                  <span v-show="edit == false" class="content">{{ruleForm.noticeName}}</span>
                  <el-button v-show="edit == false"  @click="clearNotice" type="primary" size="small" style="color: white;margin-left:10px" >清除</el-button>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row class="row">
            <el-col :span="24">
              <el-form-item>
                <el-button v-show="(edit == false)&&(this.role != 1)" type="primary" size="small" @click="edit = true" >编辑</el-button >
                <el-button v-show="edit == true" type="primary" size="small"  @click="edit = false" plain >取消</el-button>
                <el-button type="primary"  size="small" v-show="edit == true" @click="submitForm('ruleForm')">提交</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-form>

    <template v-if="userPage">
      <WearAddUser @update="updateUser" @cancel="this.userPage = false"></WearAddUser>
    </template>
    <template v-if="managerPage">
      <WearAddManager @update="updateManager" @cancel="this.managerPage = false"></WearAddManager>
    </template>
  </div>

</template>

<script>
import {getManagerInfo,editWearInfo} from '../../../api/api'
import session from "../../../store/store";
import WearAddUser from './WearAddUser.vue'
import WearAddManager from './WearAddManager.vue'
export default {
  components: {
    WearAddUser,
    WearAddManager
  },
  data() {
    return {
      userPage:false,
      managerPage: false,
      edit: false,
      role: 0,
      ruleForm: {
        userName: "",
        noticeName:"",
      },
      rules: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.role = session.getLoginInfo().accountType;
    },
    getParentData(data) {
      this.ruleForm = data;
      this.getManagerInfo();
    },
    getManagerInfo() {
      var param = {
        uuid: this.ruleForm.noticeUuid,
      }
      getManagerInfo(param).then(res => {
        if (res.data.code == 200) {
          this.ruleForm.noticeName = res.data.dataInfo.name
        } 
      })
    },
    clearUser() {
      this.$confirm('确定清空绑定用户?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.ruleForm.unitUuid = session.getLoginInfo().unitUuid,
          this.ruleForm.idcard = '0000'
          editWearInfo(this.ruleForm).then(res => {
            if (res.data.code == 200) {
              this.ruleForm.userName = ''
              this.$message({ message: '清空成功',  type: 'success'});
            } else {
              this.$message.error({ message: res.data.message});
            }
          })
        }).catch(() => {});
    },
    clearNotice() {
      this.$confirm('确定清空管理员?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.ruleForm.unitUuid = session.getLoginInfo().unitUuid,
          this.ruleForm.noticeUuid = '0000'
          editWearInfo(this.ruleForm).then(res => {
            if (res.data.code == 200) {
              this.ruleForm.noticeName = ''
              this.$message({ message: '清空成功',  type: 'success'});
            } else {
              this.$message.error({ message: res.data.message});
            }
          })
        }).catch(() => {});
    },
    updateUser(data) {
      this.ruleForm.idcard = data.idcard;
      this.ruleForm.userName = data.name;
    },
    updateManager(data) {
      this.ruleForm.noticeUuid = data.uuid;
      this.ruleForm.noticeName = data.name;
    },
    submitForm(ruleName) {
      this.$refs[ruleName].validate((valid) => {
        if (valid) {
         this.editWearInfo();
        } else {
          return false;
        }
      });
    },
    editWearInfo() {
      this.ruleForm.unitUuid = session.getLoginInfo().unitUuid,
      editWearInfo(this.ruleForm).then(res => {
        if (res.data.code == 200) {
          this.$message({ message: '修改成功',  type: 'success'});
          this.cancelEdit();
        } else {
          this.$message.error({ message: res.data.message});
        }
      })
    },
    cancelEdit() {
      this.edit = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  text-align: center;
  margin: 20px 0px;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -19px;
}
.input {
  width: 50%;
}
hr {
  border-color: #f4f4f526;
}
.title {
  margin-right: 25px;
  font-size: 16px;
  color: #8D8D8D;
}
.box {
  width: 75%;
}
.content {
  font-size: 16px;
  margin-left: 10px;
}
.row {
  margin: 20px 0px 20px 0px;
}

</style>
