<template>
  <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="150px" class="demo-ruleForm">
    <div style="background-color: white">
      <div class="box">
        <el-row class="row">
          <el-col :span="24">
              <el-form-item prop="tcInterval">
                <template #label><span class="title">体温监测设置</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.tcInterval" class="input" >
                  <template #append>
                    <el-button type="primary" style="color: white" @click="synInfo(1)">测量</el-button>
                  </template>
                </el-input>
                <span v-show="edit == false" class="content">{{ruleForm.tcInterval}} (分钟)</span>
                <el-button v-show="(edit == false)&&(this.role != 1)" type="warning" size="small" style="margin-left:10px;color: white" @click="synInfo(1)">同步</el-button>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24">
              <el-form-item prop="bpInterval">
                <template #label><span class="title">血压心率监测</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.bpInterval" class="input" >
                  <template #append>
                    <el-button type="primary" style="color: white" @click="synInfo(2)">测量</el-button>
                  </template>
                </el-input>
                <span v-show="edit == false" class="content">{{ruleForm.bpInterval}} (分钟)</span>
                <el-button v-show="(edit == false)&&(this.role != 1)" type="warning" size="small" style="margin-left:10px;color: white" @click="synInfo(2)">同步</el-button>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24">
              <el-form-item prop="workMode">
                <template #label><span class="title">&emsp;&emsp;轨迹模式</span></template>
                <el-select v-show="edit == true" v-model="ruleForm.workMode" placeholder="正常模式" size="large" class="input">
                  <el-option v-for="item in workModeOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
                <span v-show="edit == false" class="content" v-if="ruleForm.workMode == 1">正常模式(60分钟)</span>
                <span v-show="edit == false" class="content" v-if="ruleForm.workMode == 2">省电模式(24小时)</span>
                <span v-show="edit == false" class="content" v-if="ruleForm.workMode == 3">监护模式(10分钟)</span>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row">
          <el-col :span="24">
              <el-form-item prop="traceModel">
                <template #label><span class="title">&emsp;&emsp;定位模式</span></template>
                <el-select v-show="edit == true" v-model="ruleForm.locType" placeholder="WIFI优先" size="large" class="input">
                  <el-option v-for="item in traceModelOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
                <span v-show="edit == false" class="content" v-if="ruleForm.locType == 1">GPS优先</span>
                <span v-show="edit == false" class="content" v-if="ruleForm.locType == 2">WIFI优先</span>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row">
          <el-col :span="24">
            <el-form-item prop="name">
              <el-button v-show="(edit == false)&&(this.role != 1)" type="primary" size="small" @click="edit = true" >编辑</el-button>
              <el-button v-show="edit == true" type="primary" size="small"  @click="edit = false" plain >取消</el-button>
              <el-button type="primary"  size="small" v-show="edit == true" @click="submitForm('ruleForm')">提交</el-button>
            </el-form-item>
          </el-col>
        </el-row>

        <hr />
        <div class="setTab">
          <el-tabs tab-position="top" @tab-click="handleClick">
            <el-tab-pane label="健康提醒" >
              <WearAlarm ref="alarmView"></WearAlarm>
            </el-tab-pane>
            <el-tab-pane label="电话本" >
              <WearDialing ref="dialingView"></WearDialing>
            </el-tab-pane>
            <el-tab-pane label="SOS监护人">
              <WearGuardian ref="guardianView"></WearGuardian>
            </el-tab-pane>
          </el-tabs>
        </div>
        
      </div>
    </div>
  </el-form>

</template>

<script>
import {getWearSetInfo,editWearSetInfo,synWearInfo} from '../../../api/api'
import session from "../../../store/store";
import WearAlarm from "./WearAlarm.vue";
import WearDialing from "./WearDialing.vue";
import WearGuardian from "./WearGuardian.vue";
export default {
  components: {
    WearAlarm,
    WearDialing,
    WearGuardian
  },
  data() {
    return {
      edit: false,
      role: 0,
      deviceId:'',
      deviceData:{},
      workModeOption:[
        {value: 3,label: '监护模式(10分钟)'},
        {value: 1,label: '正常模式60分钟)'},
        {value: 2,label: '省电模式(24小时)'},
      ],
      traceModelOption:[
        {value: 1,label: 'GPS定位优先'},
        {value: 2,label: 'WIFI定位优先'}
      ],
      ruleForm: {
        workMode:2,
        locType:2,
      },
      rules: {
        tcInterval:[
          {required: true,message: "不能为空", trigger: "blur"},
        ],
        bpInterval:[
          {required: true,message: "不能为空", trigger: "blur"},
        ],
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.role = session.getLoginInfo().accountType;
    },
    getParentData(wearData) {
      this.deviceData = wearData
      this.deviceId = wearData.deviceId
      this.getWearSetInfo();
    },
    getWearSetInfo() {
      let param = {
        deviceId:this.deviceId
      }
      getWearSetInfo(param).then(res => {
        if (res.data.code == 200) {
          this.ruleForm = res.data.dataInfo;
          this.ruleForm.deviceId = this.deviceId;

          setTimeout(() => {
            this.$refs.alarmView.getParentData(this.ruleForm);
          }, 200);
        }
      })
    },
    synInfo(type) {
      let param;
      if(1 == type) {
        param = {
          deviceId:this.deviceId,
          func:'rt_tc'
        }
      }else {
        param = {
          deviceId:this.deviceId,
          func:'rt_bp'
        }
      }

      synWearInfo(param).then(res => {
        if (res.data.code == 200) {
          this.$message.success({ message: "请求已发出"});
        } else {
          this.$message.error({ message: res.data.msg });
        }
      })

    },
    submitForm(ruleName) {
      this.$refs[ruleName].validate((valid) => {
        if (valid) {
          this.editWearSetInfo();
        } else {
          return false;
        }
      });
    },
    editWearSetInfo() {
      this.ruleForm.func = 'interval'
      editWearSetInfo(this.ruleForm).then(res => {
        if (res.data.code == 200) {
          this.$message({ message: '新增成功',  type: 'success'});
          this.closeEdit();
          this.getWearSetInfo();
          
        } else {
          this.$message.error({ message: res.data.message});
        }
      })
    },
    closeEdit() {
      this.edit = false
    },
    handleClick(tab) {
      if(0 == tab.index) {
        setTimeout(() => {
          this.$refs.alarmView.getParentData(this.ruleForm);
        }, 200);
      }else if(1 == tab.index) {
        setTimeout(() => {
          this.$refs.dialingView.getParentData(this.ruleForm,this.deviceData);
        }, 200);
      }else if(2 == tab.index) {
        setTimeout(() => {
          this.$refs.guardianView.getParentData(this.ruleForm);
        }, 200);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  text-align: center;
  margin: 20px 0px;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -19px;
}
.input {
  width: 50%;
}
hr {
  border-color: #f4f4f526;
}
.page {
  padding: 0px 20px;
}
.title {
  margin-right: 25px;
  font-size: 16px;
  color: #8D8D8D;
}
.box {
  // width: 75%;
  padding: 20px 20px;
  background-color: white;
  border-radius: 4px;
}
.content {
  font-size: 16px;
  margin-left: 10px;
}
.row {
  margin: 20px 0px 20px 0px;
}


</style>

<style lang="scss">
.setTab {
  .el-tabs__item {
    font-size:16px !important;
    // color: #676767;
    font-weight: 200
  }
} 

</style>