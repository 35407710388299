<template>
  <div class="page">
    <ls-title-header @back="cancel" title="设备详情"></ls-title-header>
    <div class="box">
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="设备信息" name="first">
          <WearBaseInfo ref="baseView" @cancel="cancel"></WearBaseInfo>
        </el-tab-pane>
        <el-tab-pane label="功能设置" name="second">
          <WearSet ref="setView" @cancel="cancel"></WearSet>
        </el-tab-pane>
        <el-tab-pane label="使用记录" name="third">
          <WearRecord ref="recordView" @cancel="cancel"></WearRecord>
        </el-tab-pane>

        
      </el-tabs>
    </div>
  </div>
</template>

<script>
import WearBaseInfo from "./WearBaseInfo.vue";
import WearRecord from './WearRecord.vue'
import WearSet from './WearSet.vue'
export default {
  components: {
    WearBaseInfo,
    WearRecord,
    WearSet
  },
  data() {
    return {
      wearData:null,
      activeName: "first",
    };
  },
  methods: {
    getParentData(wearData) {
      this.wearData = wearData;
      setTimeout(() => {
        this.$refs.baseView.getParentData(wearData);
      }, 200);
    },
    cancel() {
      this.$emit("cancel");
    },
    handleClick(tab) {
      if(0 == tab.index) {
        setTimeout(() => {
          this.$refs.baseView.getParentData(this.wearData);
        }, 200);
      }else if(1 == tab.index) {
        setTimeout(() => {
          this.$refs.setView.getParentData(this.wearData);
        }, 200);
      }else if(2 == tab.index) {
        setTimeout(() => {
          this.$refs.recordView.getParentData(this.wearData);
        }, 200);
      }
    },
  },
};
</script>

<style scoped>
/* .page {
  padding: 40px 20px 40px 20px;
  background-color: white;
  border-radius: 4px;
} */
.page {
  padding: 0px 20px;
}
.box {
  /* width: 75%; */
  padding: 20px 20px;
  background-color: white;
  border-radius: 4px;
}
</style>
<style>
.el-tabs__item {
    font-size: 18px;
}
.el-tabs__nav-wrap::after {
  position: static !important;
}
</style>

