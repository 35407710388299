<template>
  <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" class="demo-ruleForm">
    <div style="background-color: white">
      <div class="box">
        <el-row class="row">
          <el-col :span="6">
              <el-form-item>
                <template #label> <span class="title">姓名1</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.name1" class="input" ></el-input>
                <span v-show="edit == false" class="content">{{ruleForm.name1}}</span>
              </el-form-item>
          </el-col>
          <el-col :span="8">
              <el-form-item>
                <template #label> <span class="title">号码1</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.tel1" class="input" ></el-input>
                <span v-show="edit == false" class="content">{{ruleForm.tel1}}</span>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row">
          <el-col :span="6">
              <el-form-item>
                <template #label><span class="title">姓名2</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.name2" class="input" ></el-input>
                <span v-show="edit == false" class="content">{{ruleForm.name2}}</span>
              </el-form-item>
          </el-col>
          <el-col :span="8">
              <el-form-item>
                <template #label><span class="title">号码2</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.tel2" class="input" ></el-input>
                <span v-show="edit == false" class="content">{{ruleForm.tel2}}</span>
              </el-form-item>
          </el-col>
        </el-row>


        <el-row class="row">
          <el-col :span="6">
              <el-form-item>
                <template #label><span class="title">姓名3</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.name3" class="input" ></el-input>
                <span v-show="edit == false" class="content">{{ruleForm.name3}}</span>
              </el-form-item>
          </el-col>
          <el-col :span="8">
              <el-form-item>
                <template #label><span class="title">号码3</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.tel3" class="input" ></el-input>
                <span v-show="edit == false" class="content">{{ruleForm.tel3}}</span>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row" v-if="this.deviceData.deviceSubId != 1">
          <el-col :span="6">
              <el-form-item>
                <template #label><span class="title">姓名4</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.name4" class="input" ></el-input>
                <span v-show="edit == false" class="content">{{ruleForm.name4}}</span>
              </el-form-item>
          </el-col>
          <el-col :span="8">
              <el-form-item>
                <template #label><span class="title">号码4</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.tel4" class="input" ></el-input>
                <span v-show="edit == false" class="content">{{ruleForm.tel4}}</span>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row" v-if="this.deviceData.deviceSubId != 1">
          <el-col :span="6">
              <el-form-item>
                <template #label><span class="title">姓名5</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.name5" class="input" ></el-input>
                <span v-show="edit == false" class="content">{{ruleForm.name5}}</span>
              </el-form-item>
          </el-col>
          <el-col :span="8">
              <el-form-item>
                <template #label><span class="title">号码5</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.tel5" class="input" ></el-input>
                <span v-show="edit == false" class="content">{{ruleForm.tel5}}</span>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row" v-if="this.deviceData.deviceSubId != 1">
          <el-col :span="6">
              <el-form-item>
                <template #label><span class="title">姓名6</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.name6" class="input" ></el-input>
                <span v-show="edit == false" class="content">{{ruleForm.name6}}</span>
              </el-form-item>
          </el-col>
          <el-col :span="8">
              <el-form-item>
                <template #label><span class="title">号码6</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.tel6" class="input" ></el-input>
                <span v-show="edit == false" class="content">{{ruleForm.tel6}}</span>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row" v-if="this.deviceData.deviceSubId != 1">
          <el-col :span="6">
              <el-form-item>
                <template #label><span class="title">姓名7</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.name7" class="input" ></el-input>
                <span v-show="edit == false" class="content">{{ruleForm.name7}}</span>
              </el-form-item>
          </el-col>
          <el-col :span="8">
              <el-form-item>
                <template #label><span class="title">号码7</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.tel7" class="input" ></el-input>
                <span v-show="edit == false" class="content">{{ruleForm.tel7}}</span>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row" v-if="this.deviceData.deviceSubId != 1">
          <el-col :span="6">
              <el-form-item>
                <template #label><span class="title">姓名8</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.name8" class="input" ></el-input>
                <span v-show="edit == false" class="content">{{ruleForm.name8}}</span>
              </el-form-item>
          </el-col>
          <el-col :span="8">
              <el-form-item>
                <template #label><span class="title">号码8</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.tel8" class="input" ></el-input>
                <span v-show="edit == false" class="content">{{ruleForm.tel8}}</span>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row" v-if="this.deviceData.deviceSubId != 1">
          <el-col :span="6">
              <el-form-item>
                <template #label><span class="title">姓名9</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.name9" class="input" ></el-input>
                <span v-show="edit == false" class="content">{{ruleForm.name9}}</span>
              </el-form-item>
          </el-col>
          <el-col :span="8">
              <el-form-item>
                <template #label><span class="title">号码9</span></template>
                <el-input v-show="edit == true" v-model="ruleForm.tel9" class="input" ></el-input>
                <span v-show="edit == false" class="content">{{ruleForm.tel9}}</span>
              </el-form-item>
          </el-col>
        </el-row>


        <el-row class="row">
          <el-col :span="12">
            <el-form-item>
              <el-button v-show="(edit == false)&&(this.role != 1)" type="primary" size="small" @click="edit = true" >编辑</el-button>
              <el-button v-show="edit == true" type="primary" size="small"  @click="edit = false" plain >取消</el-button>
              <el-button type="primary"  size="small" v-show="edit == true" @click="submitForm('ruleForm')">提交</el-button>
            </el-form-item>
          </el-col>
        </el-row>

      </div>
    </div>
  </el-form>

</template>

<script>
import {editWearSetInfo} from '../../../api/api'
import session from "../../../store/store";
export default {
  data() {
    return {
      edit: false,
      role: 0,
      deviceData:{},
      ruleForm: {
      },
      rules: {
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    getParentData(data,deviceData) {
      this.deviceData = deviceData
      this.ruleForm = data
      
    },
    init() {
      this.role = session.getLoginInfo().accountType;
    },
    submitForm(ruleName) {
      this.$refs[ruleName].validate((valid) => {
        if (valid) {
          this.editWearSetInfo();
        } else {
          return false;
        }
      });
    },
    editWearSetInfo() {
      this.ruleForm.func = 'keynumber'
      editWearSetInfo(this.ruleForm).then(res => {
        if (res.data.code == 200) {
          this.$message({ message: '修改成功',  type: 'success'});
          this.closeEdit();
        } else {
          this.$message.error({ message: res.data.message});
        }
      })
    },

    closeEdit() {
      this.edit = false
    },

  },
};
</script>

<style lang="scss" scoped>
.pagination {
  text-align: center;
  margin: 20px 0px;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -19px;
}
.input {
  width: 90%;
}
hr {
  border-color: #f4f4f526;
}
.page {
  padding: 0px 20px;
}
.title {
  margin-right: 25px;
  font-size: 16px;
  color: #8D8D8D;
}
.box {
  width: 75%;
  // padding: 20px 20px;
  background-color: white;
  border-radius: 4px;
}
.content {
  font-size: 16px;
  margin-left: 10px;
}
.row {
  margin: 20px 0px 20px 0px;
}


</style>

